import { AppBar, Avatar, Box, Button, Link, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import i18n from '../lang/i18n'
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined'
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined'
import { useNavigate } from 'react-router-dom'
import { setLocalStorageItem } from '../services/LocalStorage'
import React, { useEffect } from 'react'
import { type AmwalReduxProps, connector } from '../redux/connector'
import mixpanel from '../services/mixpanel'

const Intro = (props: AmwalReduxProps): JSX.Element => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  useEffect(() => {
    if (props.order && process.env.REACT_APP_MIXPANEL === 'true') {
      mixpanel.track(`${props.order.merchant_business_name}`, {
        page: '/intro',
        transaction: props.order?.id,
        client_phone: props.order?.client_phone_number
      })
    }
  }, [])
  return (
    <Box display="flex" flexDirection="column" minHeight={'100vh'} className={theme.palette.mode === 'dark' ? 'dark' : undefined} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <AppBar elevation={0} position="static" color='transparent' style={{
        alignItems: 'center'
      }}>
        <Link
          href="https://www.amwal.tech"
          target="_blank">
          <img style={{
          }}
            alt="amwal"
            height={50}
            width={100}
            src={theme.palette.mode === 'dark' ? '/assets/images/logo-white.svg' : '/assets/images/logo-black.svg'} />
        </Link>
      </AppBar>
      <Box padding={2} margin={'auto'}>
        <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
          {t('Buy with one click on your next visits to this store or any store that has Amwal!')}
        </Typography>
        <Typography variant="subtitle1" fontWeight={'bold'} marginTop={5}>
          {t('How does Amwal work?')}
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={theme.palette.mode === 'light' ? 'assets/images/cart-black.svg' : 'assets/images/cart-white.svg'}></Avatar>
            </ListItemAvatar>
            <ListItemText>
              {t('Add your card and address information')}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <FingerprintOutlinedIcon fontSize="large" />
            </ListItemAvatar>
            <ListItemText>
              {t('Activate Face ID or Fingerprint')}
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <BoltOutlinedIcon fontSize="large" />
            </ListItemAvatar>
            <ListItemText>
              {t('Enjoy the fastest and most secure buying experience with Amwal')}
            </ListItemText>
          </ListItem>
        </List>
        <Button variant="contained" fullWidth style={{ marginTop: 10 }} onClick={() => {
          setLocalStorageItem('skipIntro', '1')
          navigate('/phone')
        }} >
          {t('Proceed')}
        </Button>
      </Box>
    </Box>
  )
}

export default connector(Intro)
