/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { connect, type ConnectedProps } from 'react-redux'

import { type UserAction, type UserState } from './reducers/user'
import { type OrderAction, type OrderState } from './reducers/order'
import { bindActionCreators, type Dispatch } from 'redux'

import * as user_actions from './actions/user'
import * as order_actions from './actions/order'

export interface AmwalState {
  userState: UserState
  orderState: OrderState
}

export const mapAmwalStateToProps = (state: AmwalState) => ({
  currentUser: state.userState.currentUser,
  addressStepDone: state.userState.addressStepDone,
  browserSupportsWebauthnAutofill: state.userState.browserSupportsWebauthnAutofill,
  authToken: state.userState.authToken,
  loading: state.userState.loading,
  order: state.orderState.order,
  config: state.orderState.config,
  session_id: state.orderState.session_id,
  paymentMethod: state.orderState.paymentMethod,
  paymentOption: state.orderState.paymentOption,
  userError: state.userState.error,
  selectedInstallmentPlan: state.orderState.selectedInstallmentPlan,
  selectedInstallmentOption: state.orderState.selectedInstallmentOption,
  installmentOptions: state.orderState.installmentOptions
})

export const mapAmwalDispatchToProps = (dispatch: Dispatch<OrderAction | UserAction>) => ({
  user_actions: bindActionCreators(user_actions, dispatch),
  order_actions: bindActionCreators(order_actions, dispatch)
})

export const connector = connect(mapAmwalStateToProps, mapAmwalDispatchToProps)

export type AmwalReduxProps = ConnectedProps<typeof connector>
