import { startAuthentication } from '@simplewebauthn/browser'
import { serverURL } from '../../config'
import { type AmwalState } from '../connector'
import { UserActions, type UserDispatchFunction } from '../reducers/user'
import { type AuthenticationCredentialJSON, type PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types'
import { setLocalStorageItem } from '../../services/LocalStorage'
import { type IAddress, type ICard } from '../../model'

export function clearData () {
  return (dispatch: UserDispatchFunction) => {
    dispatch({ type: UserActions.CLEAR_DATA })
  }
}

export function clearError () {
  return (dispatch: UserDispatchFunction) => {
    dispatch({ type: UserActions.CLEAR_ERROR })
  }
}

export function otpLogin (phoneNumber: string, otp: string) {
  return async function (dispatch: UserDispatchFunction,
    getState: () => AmwalState) {
    try {
      const state = getState()
      const response = await fetch(`${serverURL}/client/signin/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          otp,
          phone_number: phoneNumber,
          card_type: state.orderState.order?.type,
          transaction_id: state.orderState.order?.id
        })
      })
      if (!response.ok) {
        throw new Error(response.statusText || await response.text())
      }
      const result = await response.json()
      dispatch({
        type: UserActions.LOGIN,
        updateUser: result.user,
        authToken: result.token
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: UserActions.SET_ERROR,
        error: err || 'error'
      })
    }
  }
}

export function webauthLogin (requestOptionsJSON: PublicKeyCredentialRequestOptionsJSON, useBrowserAutofill?: boolean, iOSWeird?: boolean) {
  return async (dispatch: UserDispatchFunction,
    getState: () => AmwalState) => {
    try {
      const state = getState()
      let attResp: AuthenticationCredentialJSON | undefined
      try {
        if (iOSWeird) {
          attResp = await startAuthentication(requestOptionsJSON, useBrowserAutofill)
        }
      } catch (err) {
        console.log(err)
      }
      if (!attResp) {
        attResp = await startAuthentication(requestOptionsJSON, useBrowserAutofill)
      }
      const verificationResp = await fetch(`${serverURL}/client/signin/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...attResp,
          card_type: state.orderState.order?.type,
          transaction_id: state.orderState.order?.id,
          session_id: state.orderState.session_id
        }),
        credentials: 'include'
      })
      if (!verificationResp.ok) {
        throw new Error(verificationResp.statusText || await verificationResp.text())
      }
      const result = await verificationResp.json()
      // Wait for the results of verification
      setLocalStorageItem(attResp.id, '1')
      setLocalStorageItem('lastCredId', attResp.id)
      if (state.orderState.order && !state.orderState.order?.client_phone_number) {
        try {
          await fetch(`${serverURL}/transactions/${state.orderState.order.id}/phone`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              phone_number: result.user.phone_number,
              session_id: state.orderState.session_id
            }),
            credentials: 'include'
          })
        } catch (err) {
          console.log(err)
        }
      }
      dispatch({
        type: UserActions.LOGIN,
        updateUser: result.user,
        authToken: result.token
      })
    } catch (err: any) {
      console.log(err)
      dispatch({
        type: UserActions.SET_ERROR,
        error: { ...err, useBrowserAutofill }
      })
    }
  }
}

export function setDoneWithAddressStep () {
  return (dispatch: UserDispatchFunction) => {
    dispatch({
      type: UserActions.SET_ADDRESS_STEP
    })
  }
}

export function setUserAddresses (addresses: IAddress[]) {
  return (dispatch: UserDispatchFunction) => {
    dispatch({
      type: UserActions.SET_ADDRESSES,
      addresses
    })
  }
}

export function setUserCards (cards: ICard[]) {
  return (dispatch: UserDispatchFunction) => {
    dispatch({
      type: UserActions.SET_CARDS,
      cards
    })
  }
}

export function fetchUser (authToken?: string) {
  return async function (dispatch: UserDispatchFunction) {
    try {
      const response = await fetch(`${serverURL}/client/profile`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(authToken ? { Authorization: `Token ${authToken}` } : undefined)
        }
      })
      if (!response.ok) {
        throw new Error(response.statusText || await response.text())
      }
      const result = await response.json()
      dispatch({
        type: UserActions.SET_USER,
        updateUser: result.user
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: UserActions.SET_ERROR,
        error: err || 'error'
      })
    }
  }
}

export function setSupportWebauthnAutofill () {
  return (dispatch: UserDispatchFunction) => {
    dispatch({
      type: UserActions.SET_WEBAUTHN_AUTOFILL_SUPPORT
    })
  }
}
