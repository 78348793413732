import { type IAddress, type IUser, type ICard } from '../../model'

export enum UserActions {
  CLEAR_DATA = 'CLEAR_DATA',
  LOGIN = 'LOGIN',
  SET_USER = 'SET_USER',
  SET_ADDRESSES = 'SET_ADDRESSES',
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SET_ADDRESS_STEP = 'SET_ADDRESS_STEP',
  SET_WEBAUTHN_AUTOFILL_SUPPORT = 'SET_WEBAUTHN_AUTOFILL_SUPPORT',
  SET_CARDS = 'SET_CARDS',
}

export interface UserState {
  currentUser?: IUser
  authToken?: string
  loading: boolean
  error?: any
  addressStepDone: boolean
  browserSupportsWebauthnAutofill: boolean
}

const initialState: UserState = {
  currentUser: undefined,
  authToken: undefined,
  loading: true,
  error: undefined,
  addressStepDone: false,
  browserSupportsWebauthnAutofill: false
}

export type UserAction = {
  type: UserActions.CLEAR_DATA
} | {
  type: UserActions.SET_ADDRESS_STEP
} | {
  type: UserActions.LOGIN
  authToken?: string
  updateUser: IUser
} | {
  type: UserActions.SET_USER
  updateUser: IUser
} | {
  type: UserActions.SET_ADDRESSES
  addresses: IAddress[]
} | {
  type: UserActions.SET_ERROR
  error: any
} | {
  type: UserActions.CLEAR_ERROR
} | {
  type: UserActions.SET_WEBAUTHN_AUTOFILL_SUPPORT
} | {
  type: UserActions.SET_CARDS
  cards: ICard[]
}

export type UserDispatchFunction = (a: UserAction) => void

export const user = (
  state = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case UserActions.LOGIN:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          ...action.updateUser
        },
        authToken: action.authToken
      }
    case UserActions.SET_USER:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          ...action.updateUser
        }
      }

    case UserActions.CLEAR_DATA:
      return {
        ...initialState,
        loading: false
      }

    case UserActions.SET_ADDRESSES:
      return {
        ...state,
        ...(state.currentUser
          ? {
              currentUser: {
                ...(state.currentUser),
                addresses: action.addresses
              }
            }
          : undefined)
      }

    case UserActions.SET_ERROR:
      return {
        ...state,
        error: action.error
      }
    case UserActions.CLEAR_ERROR:
      return {
        ...state,
        error: undefined
      }
    case UserActions.SET_ADDRESS_STEP:
      return {
        ...state,
        addressStepDone: true
      }
    case UserActions.SET_WEBAUTHN_AUTOFILL_SUPPORT:
      return {
        ...state,
        browserSupportsWebauthnAutofill: true
      }
    case UserActions.SET_CARDS:
      return {
        ...state,
        ...(state.currentUser
          ? {
              currentUser: {
                ...(state.currentUser),
                cards: action.cards
              }
            }
          : undefined)
      }
    default:
      return state
  }
}
