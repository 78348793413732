export const setLocalStorageItem = (key: string, value: string): void => {
  try {
    window.localStorage?.setItem(key, value)
  } catch (err) {
    console.log(err)
  }
}

export const getLocalStorageItem = (key: string): string | undefined => {
  try {
    return window.localStorage?.getItem(key) ?? undefined
  } catch (err) {
    console.log(err)
  }
  return undefined
}
