import { type IOrder, type IPaymentMethod, type InstallmentOption, type InstallmentPlan, type IPaymentOption } from '../../model'
import { type AmwalOrderConfig } from '../../services/EventListeners'
import { showApplePayOption } from '../../services/validation'

export enum OrderActionType {
  CLEAR_DATA = 'CLEAR_DATA',
  SET_ORDER = 'SET_ORDER',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_PAYMENT_OPTION = 'SET_PAYMENT_OPTION',
  SET_INSTALLMENT_INFO = 'SET_INSTALLMENT_INFO',
  SET_INSTALLMENT_OPTIONS = 'SET_INSTALLMENT_OPTIONS'
}

export interface OrderState {
  order?: IOrder
  session_id?: string
  config: AmwalOrderConfig
  paymentMethod: IPaymentMethod
  paymentOption: IPaymentOption
  selectedInstallmentPlan?: InstallmentPlan
  selectedInstallmentOption?: InstallmentOption
  installmentOptions?: InstallmentOption[]
}

const initialState: OrderState = {
  order: undefined,
  config: {
    lang: 'ar',
    id: '',
    emailRequired: true,
    street2Required: false,
    addressRequired: false,
    addressHandshake: false,
    sendExtraAddressFields: false,
    enablePrePayTrigger: false,
    shippingMethods: [],
    amount: 0,
    taxes: 0,
    discount: 0,
    fees: 0,
    convenience_fee: 0,
    allowedAddressCountries: ['SA', 'AE', 'KW', 'BH', 'OM'],
    onlyShowBankInstallment: false
  },
  paymentMethod: showApplePayOption ? 'apple_pay' : 'card_pay',
  paymentOption: 'pay_in_full',
  selectedInstallmentPlan: undefined,
  selectedInstallmentOption: undefined,
  installmentOptions: []
}

export type OrderAction = {
  type: OrderActionType.CLEAR_DATA
} | {
  type: OrderActionType.SET_ORDER
  order?: IOrder
  session_id?: string
  config?: AmwalOrderConfig
} | {
  type: OrderActionType.SET_PAYMENT_METHOD
  paymentMethod: IPaymentMethod
} | {
  type: OrderActionType.SET_PAYMENT_OPTION
  paymentOption: IPaymentOption
} | {
  type: OrderActionType.SET_INSTALLMENT_INFO
  selectedInstallmentPlan?: InstallmentPlan
  selectedInstallmentOption?: InstallmentOption
} | {
  type: OrderActionType.SET_INSTALLMENT_OPTIONS
  installmentOptions?: InstallmentOption[]
}

export const order = (state = initialState,
  action: OrderAction): OrderState => {
  switch (action.type) {
    case OrderActionType.CLEAR_DATA:
      return initialState

    case OrderActionType.SET_ORDER:
      return {
        ...state,
        ...action
      }
    case OrderActionType.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod
      }
    case OrderActionType.SET_PAYMENT_OPTION:
      return {
        ...state,
        paymentOption: action.paymentOption
      }
    case OrderActionType.SET_INSTALLMENT_INFO:
      return {
        ...state,
        selectedInstallmentPlan: action.selectedInstallmentPlan,
        selectedInstallmentOption: action.selectedInstallmentOption
      }
    case OrderActionType.SET_INSTALLMENT_OPTIONS:
      return {
        ...state,
        installmentOptions: action.installmentOptions
      }
    default:
      return state
  }
}
