import { useState, useEffect } from 'react'

export type DarkModeType = 'auto' | 'on' | 'off'

export const useThemeDetector = (): boolean => {
  const prefersDark = window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : undefined

  const [darkMode, setDarkMode] = useState<DarkModeType>(() => {
    const storedDarkMode = localStorage.getItem('darkmode') as DarkModeType | null
    return storedDarkMode ?? 'auto'
  })

  const getCurrentTheme = (matches: boolean): boolean => {
    return darkMode === 'on' || (darkMode === 'auto' && matches)
  }

  const [isDarkTheme, setIsDarkTheme] = useState(() => getCurrentTheme(prefersDark?.matches ?? false))

  const mqListener = (e: MediaQueryListEvent): void => {
    setIsDarkTheme(getCurrentTheme(e.matches))
  }

  useEffect(() => {
    if (prefersDark) {
      setIsDarkTheme(getCurrentTheme(prefersDark.matches))
      prefersDark.addEventListener('change', mqListener)
      return () => {
        prefersDark.removeEventListener('change', mqListener)
      }
    }
  }, [prefersDark])

  useEffect(() => {
    localStorage.setItem('darkmode', darkMode)
    setIsDarkTheme(getCurrentTheme(prefersDark?.matches ?? false))
  }, [darkMode, prefersDark])

  useEffect(() => {
    window.parent.postMessage('query-dark-mode', '*')
    const messageListener = (ev: MessageEvent<{ type: string, darkMode: DarkModeType }>): void => {
      if (ev.data.type === 'amwal-order-id' && ev.data.darkMode) {
        setDarkMode(ev.data.darkMode)
      }
    }
    window.addEventListener('message', messageListener)
    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [])

  return isDarkTheme
}
