import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { enableExplainer } from './services/validation'
import { nanoid } from 'nanoid'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getLocalStorageItem, setLocalStorageItem } from './services/LocalStorage'

const SENTRY_ENVIRONMENT: string = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'local'
const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
const SERVER_URL: string = process.env.REACT_APP_SERVER_URL ?? 'http://localhost:8000'

const SENTRY_DEBUG: boolean = SENTRY_ENVIRONMENT === 'dev'
const history = createBrowserHistory({ basename: '/' })
const routes = [
  { path: '/' },
  { path: '/phone' },
  { path: '/intro' },
  { path: '/checkout' },
  { path: '/single-checkout' },
  { path: '/single-address' },
  { path: '/single-card' },
  { path: '/web-auth-register' },
  { path: '/apple-pay' },
  { path: '/final/:id' }
]

if (SENTRY_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://e9b676af9a44e7aa28645c3c030f32b6@sentry.amwal.tech/3',
    integrations: [new Sentry.BrowserTracing({
      tracingOrigins: [SERVER_URL, /^\//],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes),
      idleTimeout: 60000,
      startTransactionOnLocationChange: false,
      startTransactionOnPageLoad: true
    }),
    new Sentry.Replay()
    ],
    attachStacktrace: true,
    autoSessionTracking: true,
    tracePropagationTargets: [SERVER_URL, /^\//],
    debug: SENTRY_DEBUG,
    tracesSampleRate: SENTRY_DEBUG ? 1.0 : 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: SENTRY_ENVIRONMENT,
    dist: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE
  })
}

let visitorId = getLocalStorageItem('visitor_id')
if (!visitorId) {
  visitorId = nanoid()
  setLocalStorageItem('visitor_id', visitorId)
}
const app = initializeApp({
  apiKey: 'AIzaSyCsDAwwc6mEmAn-gZC6W2bS2ZAAh-9Ald4',
  authDomain: 'amwal-75582.firebaseapp.com',
  projectId: 'amwal-75582',
  storageBucket: 'amwal-75582.appspot.com',
  messagingSenderId: '1041808821242',
  appId: '1:1041808821242:web:f161308f644021dd33b243',
  measurementId: 'G-1F6K0Y87MN'
})
const analytics = getAnalytics(app)
const growthbook = new GrowthBook({
  attributes: {
    id: visitorId
  },
  trackingCallback: (experiment, result) => {
    if (enableExplainer) {
      logEvent(analytics, 'experiment_viewed', {
        event_category: 'experiment',
        event_label: result.variationId,
        event_action: experiment.key,
        dimension1: `${experiment.key}:${result.variationId}`
      })
    }
  },
  enabled: true
})
/*
async function updateGrowth (): Promise<void> {
  try {
    const res = await fetch('https://cdn.growthbook.io/api/features/prod_uUdA16OAkR4T0cRHROs0s6cK3dGpAEelCfsimi6pfC8')
    const json = await res.json()
    growthbook.setFeatures(json.features)
    const IpRes = await fetch('https://ipapi.co/json/')
    const ipJson: { ip: string, country: string } = await IpRes.json()
    const browser = getBroswer()
    growthbook.setAttributes({
      deviceId: browser ? `${ipJson.ip}-${browser}` : ipJson.ip,
      company: 'Amwal',
      loggedIn: false,
      employee: false,
      country: ipJson.country,
      browser,
      url: 'https://pay.sa.amwal.tech'
    })
  } catch (e) {
    console.log(e)
  }
}
void updateGrowth()
*/

export default Sentry.withProfiler(App)
ReactDOM.render(
  <React.StrictMode>
    <GrowthBookProvider growthbook={growthbook}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </GrowthBookProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
