import { useMemo } from 'react'
import { createStore, applyMiddleware, type Store, type CombinedState } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { type AmwalState } from './connector'
import rootReducer from './reducers'
import { type UserAction } from './reducers/user'
import { type OrderAction } from './reducers/order'

export type AmwalStore = Store<CombinedState<AmwalState>, OrderAction | UserAction>

let store: AmwalStore

function initStore (): AmwalStore {
  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  )
}

export const initializeStore = (): AmwalStore => {
  const _store = store ?? initStore()

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore (): AmwalStore {
  const store = useMemo(() => initializeStore(), [])
  return store
}
